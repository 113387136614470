<template>
  <div class="wheel_content">
    <div class="popup_win"></div>
    <div class="wheel_left">
      <h2 v-html="datas.titre_animation" />
      <p v-html="datas.text_animation" />
      <div class="button_wrapper">
        <button class="desktop" id="participer" @click="roulette_spin()">
          <span v-html="datas.wheel_button" />
        </button>
     
      </div>
    </div>
    <div class="wheel_right">
      <div class="wheel_wrapper">
        <div class="wheel_arrow">
          <img
            :src="
              `https://oxoformdocs.fra1.digitaloceanspaces.com/${datas.operation_id}/wheel-arrow.png`
            "
          />
        </div>
        <div>
          <div style="z-index: 0; position: relative;overflow: hidden">
            <img
              class="roulette_wheel"
              :src="
                `https://oxoformdocs.fra1.digitaloceanspaces.com/${datas.operation_id}/wheel.png`
              "
            />
            <img
              class="roulette_center"
              :src="
                `https://oxoformdocs.fra1.digitaloceanspaces.com/${datas.operation_id}/wheel-center.png`
              "
              @click="roulette_spin()"
              onfocus="transform()"
            />
          </div>
        </div>
        <button id="participer" class="tablette_only" @click="roulette_spin()">
          <span v-html="datas.wheel_button" />
        </button>
      </div>
        <button class="mobile" @click="roulette_spin()">
          <span v-html="datas.wheel_button" />
        </button>

    </div>
  </div>
</template>
<script>
import * as confetti from "canvas-confetti";
import { useSound } from '@vueuse/sound'
import wheelsound from '@/assets/wheel.mp3'

export default {
  props: {
    lot_name: { type: String },
  },
  setup() {
    const { play, sound } = useSound(wheelsound)
      return {
          play
      }
  },   
  data: function() {
    return {
      $swalInstance: null,
      datas: this.$root.configOp.datas,
      lots: undefined,
    };
  },
  mounted() {

  //  setTimeout(()=>{ 
  //      this.play 
  //   }, 3000);
  console.log('----- OP DATA ------', this.datas);

      var lots = []
      for (let i = 0; i < this.datas.wheel_quarters; i++) {
        if(this.datas.lots[i]) {
          lots.push(this.datas.lots[i])
        } else {
          lots.push('perdu')
        }
      }
      this.lots = lots
          console.log('----- ARRAY LOTS ------',lots);
    const prizeIndex = lots.indexOf('perdu');
    console.log('----- INDEX ------',prizeIndex);
  },
  methods: {
    enjoy() {
      var canvas = document.createElement("canvas");
      canvas.id='confettis';
      document.body.appendChild(canvas);

      const myConfetti = confetti.create(canvas, {
        resize: true,
        particleCount: 100,
        spread: 160,
      });

      myConfetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
      });
    },
    async $swal(args) {
      if (!this.$swalInstance) {
        await import(/* webpackChunkName: "swal" */ "sweetalert2").then(
          (sweetAlert) => {
            this.$swalInstance = sweetAlert.default;
          }
        );
      }
      this.$swalInstance(args);
    },
    roulette_spin(lot) {
    console.log('LOT : ', lot)
      var rouletteElem = document.getElementsByClassName("roulette_wheel")[0];
      var values = this.lots
      console.log('LOT NAME : ', this.lot_name)

      let prize;
      const duration = 3000;
      const offset = -3;

      const prizeIndex = values.indexOf(this.lot_name);
      const angleSize = 360 / values.length;
      const finalAngle = -prizeIndex * angleSize;

      prize = values[prizeIndex];
      rouletteElem.style.transform =
        "rotate(" + (360 * 10 + finalAngle + offset) + "deg)";
      rouletteElem.style["transition-duration"] = `${duration / 1000}s`;

      setTimeout(() => {
         this.$emit('myEvent');
      }, duration + 2000);
    },
  },
};
</script>
<style lang="scss">
.wheel_arrow {
  position: absolute;
  width: fit-content !important;
  top: 18%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;

      @media screen and (max-width: 1024px) {
  top: 7%;

    }

    @media screen and (max-width: 768px) {
      width: 20% !important;
      max-width: unset !important;
    }
}

.wheel_wrapper {
  width: 100% !important;
  margin: 0 auto !important;
  margin-top: 40px !important;
  position: relative;

  @media screen and (max-width: '1000px') {
      margin-top: 90px !important;
  }
}

.roulette_center {
  position: absolute;
  width: 120px;
  cursor: pointer;
  position: absolute;
  top: 57.5%;
  left: 50%;
  transform: translate(-50%, -50%);

    @media screen and (max-width: 768px) {
      width: 22%;
      top: 54.5%;
    }
}

.roulette_wheel {
  width: 70%;

  max-width: unset !important;
  touch-action: auto;
  pointer-events: painted;
  transition-timing-function: cubic-bezier(0.48, 0.67, 0, 1);
  transition-duration: 5s;
  margin: 0 auto !important;
  margin-top: 12% !important;

      @media screen and (max-width: 768px) {
      width: 96%;
    }
}

.wheel_content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  position: relative !important;

    button {
        width: fit-content !important;
    }

  .wheel_left {
    width: 40%;
    p, h2 {
        margin-top: 20px;
      } 
    @media screen and (max-width: 1000px) {
      width: 100%;
      // margin-bottom: 20px;
    }
  }

  .wheel_right {
    width: 60%;
    @media screen and (max-width: 1000px) {
      width: 100%;
    }    
  }
}
</style>
