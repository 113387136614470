<template>
  <main>
    <div v-if="datas.toolbar" class="toolbar" :class="{ hide: !tool }">
      <ul>
        <li>
        <a :href="`https://bo.lucien.ai/operationV2/${datas._id}`" target="_blank"><img class="logoHouston" src="/LogoHouston.svg" /></a> 
        </li>
        <li><span class="hr"></span></li>
        <li class="devStatus">
          &Eacute;tat :
          <select @change="changeState" v-model="participation">
            <option value="open">En cours</option>
            <option value="wait">En attente</option>
            <option value="finish">Terminé</option>
            <option value="offline">Offline</option>
            <option value="overquota">Quota atteint</option>
          </select>
        </li>
        <li v-if="participation === 'open'"><span class="hr"></span></li>
        <li v-if="participation === 'open'">
          Visuel :
          <select v-model="forceLot">
            <option></option>
            <option value="home">Home</option>
            <option
              v-for="(item, index) in lots"
              :key="index"
              :value="item.name"
              >{{ item.name }}</option
            >
            <option v-if="datas.type === 'ODR'" value="done">Page done</option>
          </select>
          <a class="voir" @click="changeStatus">ok</a>
        </li>
        <li><span class="hr"></span></li>
        <li class="devIG">
          <div v-if="test">
            <b style="font-weight:normal">Participation Statut :</b>
            <select @change="changeStatusBar" v-model="test.status">
               <option value="PERD">PERD</option>
              <option value="GAGNE">GAGNE</option>
            </select>
          </div>

          <div
            v-if="
              test.status === 'GAGNE' &&
                (datas.type === 'JEU WEB' || datas.type === 'JEU SOA')
            "
          >
            <b  style="font-weight:normal">Lot :</b>
            <select @change="changeState" v-model="test.lot">
              <option value="RANDOM">RANDOM</option>
              <option
                v-for="(item, index) in lots"
                :key="index"
                :value="item.name"
                >{{ item.name }}</option
              >
            </select> 
          </div>
        </li>
        <li v-if="test.active"><span class="hr"></span></li>
        <li v-if="test.active"><input type="checkbox" id="disableunique" v-model="test.disableunique">
        <label for="disableunique">Désactiver contrôles</label></li>
      </ul> 
      <a class="closeToolbar" @click="tool = false"
        ><i class="fas fa-close"></i
      ></a>
      <a href="javascript:window.location.reload()" class="reloadToolbar"
        ><i class="fas fa-rotate"></i
      ></a>
      <div class="toolinfos">

        <ul class="uniq_toolbar" :class="{ 'barrer' : test.disableunique }">
          <li v-for="uniq in unique" :key="uniq.index">
             <b>{{ uniq.type }} :</b> <span v-if="uniq.unique">Unique</span>
             <span v-if="uniq.blockEmailWithPlus">&nbsp;&nbsp;Adresses avec + bloqués</span>
             <span v-if="uniq.blockTempEmailProviders">&nbsp;&nbsp;Providers temporaire bloqués</span>          
          </li>
          <li v-if="datas.ipUniq"><b>IP unique : </b> <span>Oui</span></li>
        </ul>

      </div>
    </div>
    <div v-if="datas.toolbar && !tool" @click="tool = true" class="toolbar_tab">
      Toolbar <i class="fa-solid fa-caret-down"></i>
    </div>
    <div class="contenair" ref="infoBox">
      <div class="popup_wrapper">
        <MenuMobile class="mobile" :current="content" :datas="datas" />
        <div
          class="nav-link"
          v-bind:class="{ nav_transparence: datas.transparence_nav_link }"
        >
          <ul>
            <li v-if="!datas.statuspage">
              <a @click="home"><i class="fas fa-home"></i></a>
            </li>
            <li v-if="participation === 'open'">
              <a
                @click="openUp('form')"
                class="quillEditor"
                v-html="datas.home_btn_txt"
              />
            </li>
            <li v-for="item in links" :key="item.message">
              <a
                :href="`${item.link}`"
                v-if="item.link"
                rel="noopener"
                :target="`${item.target || '_blank'}`"
                >{{ item.label }}</a
              >
            </li>

            <li v-if="datas.reglement_uri">
              <a
                rel="noopener"
                :href="`${datas.reglement_uri}`"
                target="_blank"
                >{{ datas.langText.reglementLink }}</a
              >
            </li>
            <li v-if="datas.modalites_uri">
              <a
                rel="noopener"
                :href="`${datas.modalites_uri}`"
                target="_blank"
                >{{ datas.langText.modalitesLink }}</a
              >
            </li>
            <li v-if="datas.politique_uri">
              <a
                rel="noopener"
                :href="`${datas.politique_uri}`"
                target="_blank"
                >{{ datas.langText.confidentialiteLink }}</a
              >
            </li>
            <li v-if="datas.mentions">
              <a class="middle-off" @click="openUp('mentions')">{{
                datas.langText.mentionsLink
              }}</a>
            </li>
            <li v-if="datas.contact">
              <a class="middle-off" @click="openUp('contact')">{{
                datas.langText.contactLink
              }}</a>
            </li>
            <li class="social_links">
              <ul
                v-if="
                  datas.facebook_link ||
                    datas.twitter_link ||
                    datas.instagram_link ||
                    datas.youtube_link ||
                    datas.tiktok_link ||
                    datas.discord_link
                "
              >
                <li v-if="datas.facebook_link">
                  <a
                    :href="`${datas.facebook_link}`"
                    rel="noopener"
                    target="_blank"
                    ><i class="fab fa-facebook-square"></i
                  ></a>
                </li>
                <li v-if="datas.twitter_link">
                  <a
                    :href="`${datas.twitter_link}`"
                    rel="noopener"
                    target="_blank"
                    ><i class="fab fa-twitter"></i
                  ></a>
                </li>
                <li v-if="datas.instagram_link">
                  <a
                    :href="`${datas.instagram_link}`"
                    rel="noopener"
                    target="_blank"
                    ><i class="fab fa-instagram"></i
                  ></a>
                </li>
                <li v-if="datas.youtube_link">
                  <a
                    :href="`${datas.youtube_link}`"
                    rel="noopener"
                    target="_blank"
                    ><i class="fab fa-youtube"></i
                  ></a>
                </li>
                <li v-if="datas.snapchat_link">
                  <a
                    :href="`${datas.snapchat_link}`"
                    rel="noopener"
                    target="_blank"
                    ><i class="fab fa-snapchat-ghost"></i
                  ></a>
                </li>
                <li v-if="datas.pinterest_link">
                  <a
                    :href="`${datas.pinterest_link}`"
                    rel="noopener"
                    target="_blank"
                    ><i class="fab fa-pinterest-square"></i
                  ></a>
                </li>
                <li v-if="datas.linkedin_link">
                  <a
                    :href="`${datas.linkedin_link}`"
                    rel="noopener"
                    target="_blank"
                    ><i class="fab fa-linkedin"></i
                  ></a>
                </li>
                <li v-if="datas.discord_link">
                  <a
                    :href="`${datas.discord_link}`"
                    rel="noopener"
                    target="_blank"
                    ><i class="fab fa-discord"></i
                  ></a>
                </li>
                <li v-if="datas.tiktok_link">
                  <a
                    :href="`${datas.tiktok_link}`"
                    rel="noopener"
                    target="_blank"
                    ><i class="fa-brands fa-tiktok"></i></a>
                </li>                
              </ul>
            </li>
          </ul>
        </div>
        <div id="pop" class="popup">
          <MenuTablette
            class="tablette_only"
            :current="content"
            :datas="datas"
          />
          <div
            id="banner"
            class="banner"
            v-bind:class="{
              banner_animation_on: !datas.banner_disable_animation,
            }"
          >
            <div
              id="banner_mb"
              :style="`background-image: url(${datas.banner_link})`"
            >
              <img alt="empty" src="/vide.png" />
            </div>
          </div>

          <div id="home" class="home gab_home" v-if="participation === 'open'">
            <img alt="logo" class="logo" :src="`${datas.logo}`" />
            <h2 class="quillEditor gab_title" v-html="datas.title" />
            <h3 v-if="date_debut" class="periode">
              <span v-if="datas.lang != 'uk'">{{
                datas.langText.dateFrom
              }}</span>
              <span v-else>From</span>
              {{ date_debut }}
              <span v-if="datas.lang != 'uk'">{{ datas.langText.dateTo }}</span>
              <span v-else>To</span>
              {{ date_fin_achat }}
            </h3>

            <div
              v-if="
                datas.counter.limit &&
                  datas.counter.type &&
                  datas.counter.display != 'hidden' &&
                  datas.counter.type != 'INFORMATIVE'
              "
              class="counter"
            >
              <div class="counter-infos">
                
                
                <section v-if="datas.counter.increment">{{ datas.langText.counterTextStartIncrement }}</section>
                <section v-else>{{ datas.langText.counterTextStart }}</section>
                <span id="counter-rest">{{ datas.counter.restant }}</span>
                {{ datas.compteur_text_after }}
              </div>
              <div v-if="datas.counter.display == 'progressbar'" class="barras">
                <div class="barra">
                  <div class="barra-nivel" id="cpt" data-nivel="90%"></div>
                </div>
              </div>
            </div>

            <div
              v-if="
                datas.counter.type &&
                  datas.counter.display != 'hidden' &&
                  datas.counter.type != 'LIMITATIVE'
              "
              class="counter"

            >
            <div class="counter-infos">
                <section v-if="datas.counter.increment">{{ datas.langText.counterTextStartIncrement }}</section>
                <section v-else>{{ datas.langText.counterTextStart }}</section>
                <span id="counter-rest">{{ datas.counter.total  }}</span>
                {{ datas.compteur_text_after }}
            </div>
              
            </div>

            <p
              class="quillEditor gab_desc"
              v-if="datas.text_home && participation === 'open'"
              v-html="datas.text_home"
            />
            <div class="button_wrapper">
              <button class="desktop" id="participer" @click="openUp('form')">
                <span v-html="datas.home_btn_txt" />
              </button>
              <button
                class="tablette"
                id="participer"
                @click="openMobilePage('form')"
              >
                <span v-html="datas.home_btn_txt" />
              </button>
            </div>

            <a
              v-if="datas.modalites_uri"
              :href="datas.modalites_uri"
              target="_blank"
              rel="noopener"
              class="moda_link"
            >
              <span v-if="datas.lang != 'uk'">{{
                datas.langText.modalitesText
              }}</span>
              <span v-else>View rules</span>
            </a>

            <a
              v-if="datas.reglement_uri"
              :href="datas.reglement_uri"
              target="_blank"
              rel="noopener"
              class="moda_link"
            >
              <span v-if="datas.lang != 'uk'">{{
                datas.langText.reglementText
              }}</span>
              <span v-else>View rules</span>
            </a>
          </div>

          <div id="home" class="home" v-if="participation != 'open'">
            <img alt="logo" class="logo" :src="`${datas.logo}`" />
            <h2 class="quillEditor" v-html="datas.title" />
            <h3 class="periode">
              {{ datas.langText.dateFrom }} {{ date_debut }}
              {{ datas.langText.dateTo }} {{ date_fin_achat }}
            </h3>

            <p
              v-if="participation === 'wait'"
              class="homeclose_txt quillEditor"
              v-html="datas.text_wait"
            />
            <p
              v-if="participation === 'offline'"
              class="homeclose_txt quillEditor"
              v-html="datas.text_off"
            />
            <p
              v-if="participation === 'finish'"
              class="homeclose_txt quillEditor"
              v-html="datas.text_finish"
            />
            <p
              v-if="participation === 'overquota'"
              class="homeclose_txt quillEditor"
              v-html="datas.text_quotafull"
            />

            <a
              v-if="datas.modalites_uri"
              :href="datas.modalites_uri"
              target="_blank"
              rel="noopener"
              class="moda_link"
            >
              <span v-if="datas.lang != 'uk'">{{
                datas.langText.modalitesText
              }}</span>
              <span v-else>View rules</span>
            </a>

            <a
              v-if="datas.reglement_uri"
              :href="datas.reglement_uri"
              target="_blank"
              rel="noopener"
              class="moda_link"
            >
              <span v-if="datas.lang != 'uk'">{{
                datas.langText.reglementText
              }}</span>
              <span v-else>View rules</span>
            </a>
          </div>

          <div class="tablette-content">
            <div v-if="participation === 'open'" id="form" class="form">
              <img alt="logo" class="tablette logo" :src="`${datas.logo}`" />
              <div class="form_wrapper">
                <transition name="fade">
                  <Form
                    v-if="
                      datas.createID &&
                        !submited &&
                        participation === 'open' &&
                        !datas.statuspage
                    "
                    :class="'form_step' + step"
                    id="monform"
                    :form_id="datas.createID"
                    :server="datas.serverUrl"
                    v-on:results="getResult"
                    v-on:step-up="addStep"
                    v-on:step-down="supStep"
                    v-on:form_submit_loading="loading"
                    @on_error="viewError"
                    :testmode="test"
                    :hideError="hiderror"
                  />
                </transition>
                <small v-if="step === totalstep" class="legale"
                  >{{ datas.langText.conformText }}
                  <a :href="`${datas.politique_uri}`" target="_blank"
                    >ici</a
                  ></small
                >
                <!-- <h3 v-if="datas.env === 'TEST'" @click="endForm">test</h3> -->
              </div>
            </div>

            <div id="mentions" class="mentions">
              <img alt="logo" class="tablette logo" :src="`${datas.logo}`" />
              <div v-html="datas.mentions" />
              <button
                v-if="!datas.statuspage"
                class="middle-off retour"
                @click="home"
              >
                <span>{{ datas.langText.retourButton }}</span>
              </button>
              <button
                v-if="!datas.statuspage"
                class="tablette_only retour"
                @click="openTablettePage('form')"
              >
                <span>{{ datas.langText.retourButton }}</span>
              </button>
              <button
                v-if="!datas.statuspage"
                class="mobile retour"
                @click="openMobilePage('home')"
              >
                <span>{{ datas.langText.retourButton }}</span>
              </button>
            </div>

            <div id="contact" class="contact">
              <img alt="logo" class="tablette logo" :src="`${datas.logo}`" />
              <div v-html="datas.contact" />
              <button
                v-if="!datas.statuspage"
                class="middle-off retour"
                @click="home"
              >
                <span>{{ datas.langText.retourButton }}</span>
              </button>
              <button
                v-if="!datas.statuspage"
                class="tablette_only retour"
                @click="openTablettePage('form')"
              >
                <span>{{ datas.langText.retourButton }}</span>
              </button>
              <button
                v-if="!datas.statuspage"
                class="mobile retour"
                @click="openMobilePage('home')"
              >
                <span>{{ datas.langText.retourButton }}</span>
              </button>
            </div>

            <div
              v-if="
                (datas.type === 'ODR' ||
                  datas.type === 'FORMULAIRE' ||
                  datas.type === 'TAS') &&
                  !jeu_tas
              "
              id="done"
              class="done"
            >
              <img alt="logo" class="lg" :src="`${datas.logo}`" />

              <div v-if="endstart" class="success-animation">
                <svg
                  class="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  <circl
                    class="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />
                  <path
                    class="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
              </div>
              <div>
                <p v-html="datas.text_submited" class="no_p quillEditor" />
              </div>
              <button v-if="!forceEnd" class="middle-off retour" @click="home">
                <span>{{ datas.langText.retourButton }}</span>
              </button>
              <button
                v-if="!forceEnd"
                class="middle retour"
                @click="openMobilePage('home')"
              >
                <span>{{ datas.langText.retourButton }}</span>
              </button>
              <button
                v-if="forceEnd"
                class="middle-off retour"
                :onclick="`window.location.replace('/${datas.operation_id}')`"
              >
                <span>{{ datas.langText.retourButton }}</span>
              </button>
              <button
                v-if="forceEnd"
                class="middle retour"
                :onclick="`window.location.replace('/${datas.operation_id}')`"
              >
                <span>{{ datas.langText.retourButton }}</span>
              </button>
            </div>

            <div
              v-if="datas.type === 'JEU WEB' || datas.type === 'JEU SOA'"
              id="done"
              class="done uptotop"
            >
              <div
                class="candyrush"
                v-if="
                  datas.animation &&
                    datas.animation === 'CANDYRUSH' &&
                    animation === true
                "
              >
                <iframe
                  :src="`${datas.serverUrl}/${datas.operation_id}`"
                  frameborder="0"
                  style="overflow:auto;height:100%;width:100%;background-color:white;"
                  height="100%"
                  width="100%"
                ></iframe>
              </div>

              <div
                class="caeteagratter"
                v-if="
                  datas.animation &&
                    datas.animation === 'CARTE A GRATTER' &&
                    animation === true
                "
              >
                <Scratch
                  v-if="showAnimation"
                  @myEvent="endGame"
                  :lot_name.sync="lot_name"
                />
              </div>

              <div
                class="wheel"
                v-if="
                  datas.animation &&
                    datas.animation === 'ROUE DE LA CHANCE' &&
                    animation === true
                "
              >
                <Wheel @myEvent="endGame" :lot_name.sync="lot_name" />
              </div>

              <div
                class="bandit"
                v-if="
                  datas.animation &&
                    datas.animation === 'BANDIT MANCHOT' &&
                    animation === true
                "
              >
                BANDIT MANCHOT
              </div>

              <div class="dotation" v-if="lot && !animation">
                <div class="dotations_wrapper">
                  <div class="dotation_img">
                    <img
                      alt="libelle"
                      :src="
                        `https://oxoformdocs.fra1.digitaloceanspaces.com/${datas.operation_id}/${lot.img}`
                      "
                    />
                  </div>
                  <div class="dotation_content">
                    <img alt="logo" class="logo" :src="`${datas.logo}`" />
                    <h2 v-if="!lot.titre_mb">{{ datas.langText.winText }}</h2>
                    <h2 v-else>{{ lot.titre_mb }}</h2>
                    <h3 class="quillEditor" v-html="lot.libelle" />
                    <p v-if="!lot.informations">
                      {{ datas.langText.confirmText }}
                    </p>
                    <p v-else v-html="lot.informations" />

                    <!--<button class="middle-off retour" @click="home">
                      <span>RETOUR</span>
                    </button>
                    <button class="middle retour" @click="openMobilePage('home')">
                      <span>RETOUR</span>
                    </button> -->
                  </div>
                </div>
              </div>
              <div class="dotation" v-if="!lot && !animation">
                <div class="dotations_wrapper" v-if="perdu">
                  <div class="dotation_img desktop">
                    <img alt="libelle" :src="datas.image_lot_perdu" /><br />
                    <!-- <h2 v-if="!datas.lotperdu_titre">
                      Dommage, vous avez perdu !
                    </h2> -->
                    <h2
                      class="quillEditor"
                      style="display:block;width:100%;"
                      v-if="datas.lotperdu_titre"
                      v-html="datas.lotperdu_titre"
                    ></h2>
                  </div>
                  <div class="dotation_content">
                    <img alt="logo" class="logo" :src="`${datas.logo}`" />

                    <div class="dotation_img mobile">
                      <img
                        alt="libelle"
                        :src="
                          `https://oxoformdocs.fra1.digitaloceanspaces.com/${datas.operation_id}/lot_perdu.png`
                        "
                      /><br />
                      <!-- <h2 v-if="!datas.lotperdu_titre && perdu">
                        {{ datas.langText.loseText }}
                      </h2>
                      <h2 v-else-if="datas.lotperdu_titre && perdu">{{ datas.lotperdu_titre }}</h2> -->
                    </div>

                    <h3 v-id="datas.lotperdu_libelle">
                      {{ datas.lotperdu_libelle }}
                    </h3>
                    <p v-if="!datas.lotperdu_informations">
                      {{ datas.langText.thankText }}
                    </p>
                    <p
                      class="quillEditor"
                      v-else
                      v-html="datas.lotperdu_informations"
                    />

                    <button v-if="pdf_link" @click="openPDFLink">
                      <span>Télecharger mon code</span>
                    </button>
                    <!--<button class="middle retour" @click="openMobilePage('home')">
                      <span>RETOUR</span>
                    </button> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span v-if="style" v-html="style"></span>
    </div>
  </main>
</template>
<script>
import axios from "axios";
import Wheel from "@/components/Wheel";
import Scratch from "@/components/Scratch";
import MenuMobile from "@/components/MenuMobile";
import MenuTablette from "@/components/MenuTablette";
import Vue from "vue";
import EventBus from "@/event-bus";

var dayjs = require("dayjs");
import mongr from "@/libs/dayjsGr.js";

const locales = {
  de: () => import("dayjs/locale/de"),
  ar: () => import("dayjs/locale/ar"),
  fr: () => import("dayjs/locale/fr"),
  befr: () => import("dayjs/locale/fr"),
  benl: () => import("dayjs/locale/nl-be"),
  nl: () => import("dayjs/locale/nl"),
  pl: () => import("dayjs/locale/pl"),
  es: () => import("dayjs/locale/es"),
  "es-cat": () => import("dayjs/locale/en"),
  it: () => import("dayjs/locale/it"),
  en: () => import("dayjs/locale/en"),
  ro: () => import("dayjs/locale/ro"),
  sl: () => import("dayjs/locale/sl"),
  bg: () => import("dayjs/locale/bg"),
};

export default {
  name: "home",
  components: {
    Wheel,
    Scratch,
    MenuMobile,
    MenuTablette,
  },
  props: {
    toolbar: {
      type: Object,
    },
  },
  data: function() {
    return {
      unique : [],
      tool: true,
      test: {},
      forceLot: undefined,
      devLot: undefined,
      devStatus: undefined,
      devMode: false,
      links: undefined,
      pdf_link: undefined,
      showAnimation: false,
      jeu_tas: false,
      lot: undefined,
      lots: undefined,
      $swalInstance: null,
      datas: this.$root.configOp.datas,
      endstart: false,
      participation: this.$root.configOp.datas.participation,
      banner_link: this.$root.configOp.datas.banner_link,
      step: 1,
      submited: false,
      nb_step: 0,
      hiderror: true,
      server: undefined,
      date_debut: undefined,
      date_fin: undefined,
      date_fin_achat: undefined,
      state: "left",
      content: "home",
      contact_state: 0,
      totalstep: 0,
      perdu: false,
      animation: true,
      lot_name: undefined,
      style: undefined,
      forceEnd: false,
      testwait: false,
      testfinish: false,
    };
  },
  created() {
    window.testrv = this.testrv;
  
  },
  async mounted() {
    this.server = this.datas.serverUrl

    if (this.datas.toolbar) {
      this.test = { active: true, status: "PERD" };
      let { data } = await axios.get(
        `${this.datas.serverUrl}/api/v2/lots/${this.datas.operation_id}`,
        { withCredentials: true }
      );
      this.lots = data.lots;
      var toto = await this.getFormInfos();

      for (var step in toto.formulaire.form) {
        var fields = toto.formulaire.form[step].fields.filter((fl) => {
          return fl.name === "email" || fl.name === "iban" || fl.name === "tel";
        });
        if(fields && fields.length > 0) {
           if(fields[0].name ==='email') {
              this.unique.push({ type  :  'Email' , unique : fields[0].unique , blockEmailWithPlus : fields[0].blockEmailWithPlus, blockTempEmailProviders : fields[0].blockTempEmailProviders   })
           }
             if(fields[0].name ==='iban') {
              this.unique.push({ type  :  'Iban' , unique : fields[0].unique  })
           }     
             if(fields[0].name ==='tel') {
              this.unique.push({ type  :  'Téléphone' , unique : fields[0].unique  })
           }                  
        }
      }
   
    }

    if (this.datas.type === "ODR") {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const end = urlParams.get("end");
      if (end === "true") {
        this.forceEnd = true;
        this.endForm();
      }
    }

    if (this.$route.query.mode === "iframe") {
      setTimeout(() => {
        let message = { height: this.$refs.infoBox.clientHeight + 60 };
        window.top.postMessage(message, "*");
      }, 500);
    }

    if (this.datas.custom_css) {
      this.style = "<style>" + this.datas.custom_css + "</style>";
    }


    if(this.datas.links && this.datas.links.length) {
      var links = this.datas.links.filter(function(links) {
      return links.link != "";
    });
    }

    this.links = links;

    window.addEventListener("message", (event) => {
      if (event.data.type === "endGame") {
        this.animation = false;
      }
    });

    if (!this.datas.animation || this.datas.animation == "AUCUNE")
      this.animation = false;

    if (this.datas.statuspage) document.getElementById("participer").click();

    setTimeout(() => {
      this.totalstep = document.querySelectorAll("#monform .form-step").length;
    }, 2000);

    this.loadLocale(this.datas.lang);

    if (this.datas.counter.limit && this.participation === "open") {
      setTimeout(() => {
        if (
          this.datas.counter.display &&
          this.datas.counter.display == "progressbar"
        ) {
          const el = document.getElementById("cpt");
          el.style.width = this.datas.counter.percent + "%";
        }
        if (
          this.datas.counter.display &&
          this.datas.counter.display != "hidden"
        ) {
          const obj = document.getElementById("counter-rest");
          this.animateValue(
            obj,
            this.datas.counter.limit,
            this.datas.counter.restant,
            700
          );
        }
      }, 500);
    }
  },
  methods: {
    changeStatusBar() {
      if(this.test.status ==='PERD') {
        this.test = { "active" : true, "status" : 'PERD' }
      }      
      else if(this.test.status ==='GAGNE') {
        this.test = { "active" : true, "status" : 'GAGNE', "lot" : 'RANDOM' }
      } else {
        this.test = {  }
      } 
    },
    async getFormInfos() {
      let {
        data,
      } = await axios.get(
        `${this.datas.serverUrl}/api/v2/formulaires/${this.datas.createID}`,
        { withCredentials: true }
      );
      return data;
    },
    changeState() {
      this.home();
    },
    changeStatus() {
      if (this.forceLot === "done") {
        if (this.content != "done") this.openUp("form");
        setTimeout(() => {
          this.endForm();
        }, 200);
      } else if (this.forceLot === "home") {
        this.home();
      } else {
        if (this.content != "done") this.openUp("form");

        this.$swal({
          title: this.datas.langText.sendText,
          html: this.datas.langText.pleaseWaitText,
          // timerProgressBar: true,
          onBeforeOpen: () => {
            this.$swalInstance.showLoading();
          },
        });
        let result = {
          _id: "62da495d77d1cb0016097bd0", // fake id
          lot: this.forceLot,
          status: "GAGNE",
        };
        this.getResult(result);
      }
    },
    loadLocale() {
      
      if(!this.datas.lang) this.datas.lang = 'fr'
      var language = this.datas.lang;

      if (language === "si") language = "sl";
      if (language === "es-es") language = "es";
      if (language === "be-nl" || language === "lu-nl") language = "nl";
      if (language === "be-fr" || language === "lu-fr") language = "fr";
      if (language === "uk") language = "en";
      if (language === "gr") {
        dayjs.locale("gr", mongr);
      } else {
        console.log('-------',language)
        locales[language]().then(() => dayjs.locale(language));
        dayjs.locale(language);
      }

      setTimeout(() => {
        if (this.datas.lang != "uk") {
          this.date_debut = dayjs(this.$root.configOp.datas.date_debut).format(
            "DD MMMM YYYY"
          );
          this.date_fin = dayjs(this.$root.configOp.datas.date_fin);
          this.date_fin_achat = dayjs(
            this.$root.configOp.datas.date_fin_achat
          ).format("DD MMMM YYYY");
        } else {
          this.date_debut = dayjs(this.$root.configOp.datas.date_debut).format(
            "MMMM DD[th]"
          );
          this.date_fin = dayjs(this.$root.configOp.datas.date_fin);
          this.date_fin_achat = dayjs(
            this.$root.configOp.datas.date_fin_achat
          ).format("MMMM DD[th] YYYY");
        }
      }, 200);
    },
    emitMethod(name) {
      EventBus.$emit("setcurrent", name);
    },
    openPDFLink() {
      window.open(this.pdf_link);
    },
    endGame() {
      this.animation = false;
    },
    async $swal(args) {
      if (!this.$swalInstance) {
        await import(/* webpackChunkName: "swal" */ "sweetalert2").then(
          (sweetAlert) => {
            this.$swalInstance = sweetAlert.default;
          }
        );
      }
      this.$swalInstance(args);
    },
    endForm() {
      (this.showAnimation = true), (this.submited = true);
      this.content = "done";
      var element1 = document.getElementById("done");

      if (document.body.offsetWidth > 1000) {
        setTimeout(() => {
          this.endstart = true;
        }, 500);

        var el = document.getElementById("banner");
        if (el) el.classList.remove("bannerBack");

        setTimeout(function() {
          if (element1) {
            element1.style.left = "0px";
            element1.classList.add("moveDone");
          }
        }, 500);

        var element = document.getElementById("home");
        if (element) element.style.display = "none";

        el.classList.add("moveEnd");

        var element = document.getElementById("form");
        if (element) element.classList.add("divtoend");
      } else {
        setTimeout(() => {
          this.submited = false;
        }, 1500);

        this.endstart = true;
        element1.style.left = "0px";
        var element = document.getElementById("form");
        element.style.display = "none";
        element1.classList.add("moveDone");
        element1.style.display = "flex";
      }
    },
    openParticiperMobile() {
      document.getElementById("home").style.display = "none";
      this.openMiddlePage("form");
    },
    openTablettePage: function(name) {
      this.openMiddlePage(name);
    },
    openMobilePage: function(name) {
      document.getElementById("home").style.display = "none";

      this.openMiddlePage(name);
      this.checkBannerForStep(name);

      if (name == "home") {
        document.getElementById("banner").style.display = "block";
      } else {
        document.getElementById("banner").style.display = "none";
      }
      this.emitMethod(name);
      this.content = name;

    },
    openMiddlePage: function(name) {
      document.getElementById("done").style.display = "none";
      document.getElementById("form").style.display = "none";
      document.getElementById("mentions").style.display = "none";
      document.getElementById("contact").style.display = "none";
      document.getElementById(name).style.opacity = 1;

      if (name === "form") {
        document.getElementById(name).style.display = "block";
      } else {
        document.getElementById(name).style.display = "flex";
      }
    },
    closeSubmited: function() {
      var el = document.getElementById("done");
      var el1 = document.getElementById("banner");

      el.classList.remove("moveDone");
      el.classList.add("closeDone");

      setTimeout(function() {
        el1.classList.add("banner-left");
        el.classList.remove("closeDone");
        el.style.left = "99999px";
      }, 1000);

      el1.classList.remove("moveEnd");
      el1.classList.remove("banner-left");
      el1.classList.add("bannerBack");

      var el2 = document.getElementById("form");
      el2.classList.remove("left");
      el2.classList.remove("divtoend");

      this.submited = false;
    },
    openUp(name) {
      this.checkBannerForStep(name);

      if (this.submited == true) this.closeSubmited();
      if (this.content == name) return false;

      if (this.state == "right") {
        var el0 = document.getElementById(this.content); // ancien contenu
        el0.classList.remove("banner-left");
        el0.classList.add("hidetoleft");

        var el = document.getElementById(name); // nouveau contenu
        el.classList.remove("close");
        el.classList.remove("left");
        el.classList.add("banner-left");

        setTimeout(function() {
          el.classList.remove("banner-left");
          el.classList.add("left");
          el0.classList.remove("left");
          el0.classList.remove("hidetoleft");
        }, 500);
      } else {
        var element = document.getElementById("banner");
        element.classList.remove("banner-right");
        element.classList.add("banner-left");

        const cbox = document.querySelectorAll("#mentions, #contact");
        for (let i = 0; i < cbox.length; i++) {
          cbox[i].classList.remove("left");
          cbox[i].classList.add("close");
        }
        var el = document.getElementById(name);
        el.classList.remove("close");
        el.classList.add("left");
      }
      this.state = "right";
      this.content = name;
    },
    home: function() {
      var element = document.getElementById("banner");
      this.checkBannerForStep("home");

      if (this.submited == true) {
        this.closeSubmited();
      }

      if (this.state != "left") {
        var el = document.getElementById("home");
        el.style.display = "flex";
        element.classList.remove("bannerBack");
        element.classList.remove("banner-left");
        element.classList.add("banner-right");

        setTimeout(function() {
          const cbox = document.querySelectorAll("#mentions, #contact");
          for (let i = 0; i < cbox.length; i++) {
            cbox[i].classList.remove("left");
          }
        }, 500);
        this.state = "left";
      }
      this.content = "home";
    },
    animateValue: function(obj, start, end, duration) {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        obj.innerHTML = Math.floor(
          progress * (parseInt(end) - parseInt(start)) + parseInt(start)
        );
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    },
    loading(value) {
      if (value === true) {
        this.$swal({
          title: this.datas.langText.sendText,
          html: this.datas.langText.pleaseWaitText,
          timerProgressBar: true,
          onBeforeOpen: () => {
            this.$swalInstance.showLoading();
          },
        });
      }
    },
    addStep() {
      this.step = this.step + 1;
      this.checkBannerForStep("form");
    },
    supStep() {
      this.step = this.step - 1;
      this.checkBannerForStep("form");
    },
    changeBanner(url) {
      var bannerDiv = document.querySelector("#banner_mb");
      bannerDiv.classList.add("FadeOut");

      setTimeout(() => {
        bannerDiv.style.backgroundImage = "url(" + url + ")";
      }, 800);

      setTimeout(() => {
        bannerDiv.classList.add("FadeIn");
      }, 1000);

      setTimeout(() => {
        bannerDiv.classList.remove("FadeIn");
        bannerDiv.classList.remove("FadeOut");
      }, 2000);
    },
    checkBannerForStep(name) {
      var bannerDiv = document.querySelector("#banner_mb");
      var regExp = /\"([^)]+)\"/;
      var matches = regExp.exec(bannerDiv.style.backgroundImage);
      var newbanner = false;
      var url = this.datas.banner_link;

      if (name === "form") {
        if (this.datas.banner_steps[this.step].length) {
          newbanner = true;
          url = this.datas.banner_steps[this.step][0].uri;
        }
      } else {
        if (matches[1] != this.datas.banner_link) {
          newbanner = true;
          url = this.datas.banner_link;
        }
      }

      if (newbanner) {
        setTimeout(() => {
          this.changeBanner(url);
        }, 700);
      }
    },
    async getResult(result) {

      if (result._id) {
        if (this.datas.redirection_after_valid) {
          var input_text = "";
          if (result.input_text) input_text = result.input_text;
          window.location.replace(
            this.datas.redirection_after_valid +
              "?_id=" +
              result._id +
              "&input=" +
              input_text
          );
        } else {
          if (this.datas.type === "JEU WEB" || this.datas.type === "JEU SOA") {
            // GESTION DES DOTATIONS
            let {
              data,
            } = await axios.get(`${this.datas.serverUrl}/api/v2/lots/${this.datas.operation_id}`,
              { withCredentials: true }
            );

            if (data.lots) {
              var lot = data.lots.filter(function(lot) {
                return lot.name === result.lot;
              });
              this.lot = lot[0];

              if (result.status === "GAGNE") {
                this.lot_name = result.lot;
              } else {
                this.lot_name = "perdu";
              }

              if (result.code) {
                if (result.code.indexOf("https") > -1) {
                  this.pdf_link = result.code;
                }
              }

              this.perdu = true;
            } else {
              this.datas.type = "TAS";
            }
          }

          this.$swalInstance.close();
          this.endForm();
        }
      } else {
        alert("Erreur interne");
      }
    },
    viewError(err) {
      console.log(err);
      this.$swal({
        confirmButtonText: "OK",
        confirmButtonColor: "#C94330",
        title: this.datas.langText.errorText,
        text: "",
      }).then(function() {
        //  window.location.href = "/";
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/templates/gabarit1.scss";
.menu-mobile {
  @media screen and (max-width: 1000px) {
    display: none;
  }
  @media screen and (max-width: $mobile-width) {
    display: block;
  }
}

#confettis {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99999;
  top: 0;
}

.toolbar {
  width: 100%;
  background: #e4f1ff;
  position: fixed;
  z-index: 9;
  color: white;
  padding: 0px;
  font-size: 0.9rem !important;
  top: 0;
  left: 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: #444;

  .logoHouston {
    width: 150px;
  }
}

.barrer  {
  span, b {
  text-decoration:line-through;

  }

}
</style>
