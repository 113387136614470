<template>
  <div>
    <svg
      v-if="currentRouteName != 'status' && open == false"
      v-bind:class="{
        isHome: current_page === 'home',
        menutabright: data.mobile_menu_position === 'right',
        menutableft: data.mobile_menu_position === 'left',
      }"
      @click="open = true"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 384 384"
      xml:space="preserve"
      class="menutab"
    >
      <g>
        <g>
          <g>
            <rect x="0" y="277.333" width="384" height="42.667" />
            <rect
              x="0"
              y="170.667"
              width="384"
              fill="param(color)"
              height="42.667"
            />
            <rect
              x="0"
              y="64"
              width="384"
              fill="param(color)"
              height="42.667"
            />
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>

    <div class="menutablette" v-if="open">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40px"
        class="closemenutab"
        viewBox="0 0 24 24"
        @click="open = false"
      >
        <g id="close">
          <path
            id="x"
            d="M18.717 6.697l-1.414-1.414-5.303 5.303-5.303-5.303-1.414 1.414 5.303 5.303-5.303 5.303 1.414 1.414 5.303-5.303 5.303 5.303 1.414-1.414-5.303-5.303z"
          />
        </g>
      </svg>

      <div>
        <a @click="openMobilePage('home')"
          ><img class="logo" :src="`${logo}`"
        /></a>
        <img alt="logo" class="logo desktop" :src="`${logo}`" />
        <ul>
          <li v-if="participation === 'open'">
            <a @click="openMobilePage('form')" v-html="data.home_btn_txt" />
          </li>
          <li v-for="item in data.links" :key="item.message">
            <a :href="`${item.link}`" rel="noopener" target="_blank">{{
              item.label
            }}</a>
          </li>
          <li v-if="data.reglement_uri">
            <a rel="noopener" :href="`${data.reglement_uri}`" target="_blank"
              >{{ data.langText.reglementLink }}</a
            >
          </li>
          <li v-if="data.modalites_uri">
            <a rel="noopener" :href="`${data.modalites_uri}`" target="_blank"
              >{{ data.langText.modalitesLink }}</a
            >
          </li>
          <li v-if="data.politique_uri">
            <a rel="noopener" :href="`${data.politique_uri}`" target="_blank"
              >{{ data.langText.confidentialiteLink }}</a
            >
          </li>
          <li v-if="mentions">
            <a @click="openMobilePage('mentions')">{{ data.langText.mentionsLink }}</a>
          </li>
          <li v-if="contact">
            <a @click="openMobilePage('contact')">{{ data.langText.contactLink }}</a>
          </li>
          <li
            class="mobile_social_links"
            v-if="
              data.facebook_link ||
                data.twitter_link ||
                data.instagram_link ||
                data.youtube_link ||
                data.snapchat_link ||
                data.discord_link || 
                data.tiktok_link
            "
          >
            <ul>
              <li v-if="data.facebook_link">
                <a
                  :href="`${data.facebook_link}`"
                  rel="noopener"
                  target="_blank"
                  ><i class="fab fa-facebook-square"></i
                ></a>
              </li>
              <li v-if="data.twitter_link">
                <a :href="`${data.twitter_link}`" rel="noopener" target="_blank"
                  ><i class="fab fa-twitter"></i
                ></a>
              </li>
              <li v-if="data.instagram_link">
                <a
                  :href="`${data.instagram_link}`"
                  rel="noopener"
                  target="_blank"
                  ><i class="fab fa-instagram"></i
                ></a>
              </li>
              <li v-if="data.youtube_link">
                <a :href="`${data.youtube_link}`" rel="noopener" target="_blank"
                  ><i class="fab fa-youtube"></i
                ></a>
              </li>
              <li v-if="data.snapchat_link">
                <a
                  :href="`${data.snapchat_link}`"
                  rel="noopener"
                  target="_blank"
                  ><i class="fab fa-snapchat-ghost"></i
                ></a>
              </li>
              <li v-if="data.pinterest_link">
                <a
                  :href="`${data.pinterest_link}`"
                  rel="noopener"
                  target="_blank"
                  ><i class="fab fa-pinterest-square"></i
                ></a>
              </li>
              <li v-if="data.linkedin_link">
                <a
                  :href="`${data.linkedin_link}`"
                  rel="noopener"
                  target="_blank"
                  ><i class="fab fa-linkedin"></i
                ></a>
              </li>
              <li v-if="data.discord_link">
                <a :href="`${data.discord_link}`" rel="noopener" target="_blank"
                  ><i class="fab fa-discord"></i
                ></a>
              </li>
              <li v-if="data.tiktok_link">
                <a :href="`${data.tiktok_link}`" rel="noopener" target="_blank"
                  ><i class="fa-brands fa-tiktok"></i></a>
              </li>              
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Drawer from "vue-simple-drawer";

export default {
  name: "Header",
  props: ["datas", "current"],
  data: function() {
    return {
      data: this.datas,
      current_page: "home",
      page_name: this.$route.name,
      participation: this.datas.participation,
      contact: this.datas.contact,
      mentions: this.datas.mentions,
      open: false,
      logo: this.datas.logo,
      navLinks: this.datas.navLinks,
      local_link: this.datas.local_link,
    };
  },
  mounted() {},
  components: {
    Drawer,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    openMiddlePage: function(name) {
      this.openMobilePage(name);
      alert(0);
      document.getElementById("banner").style.display = "block";
      document.getElementById("home").style.display = "flex";
    },
    openMobilePage: function(name) {
      if (document.getElementById("done") !== null)
        document.getElementById("done").style.display = "none";
      if (document.getElementById("home") !== null)
        document.getElementById("home").style.display = "none";
      if (document.getElementById("form") !== null)
        document.getElementById("form").style.display = "none";
      if (document.getElementById("mentions") !== null)
        document.getElementById("mentions").style.display = "none";
      if (document.getElementById("contact") !== null)
        document.getElementById("contact").style.display = "none";

      if (name == "home") {
        document.getElementById(name).style.display = "flex";
      } else {
        document.getElementById(name).style.display = "block";
      }

      if (name == "home") {
        document.getElementById("banner").style.display = "block";
      } else {
        document.getElementById("banner").style.display = "none";
      }
      this.toggle();
    },
  },
};
</script>

<style lang="scss">
.menutablette {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 9;
}

.closemenutab {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.menutab {
  position: absolute;
  z-index: 99999999;
  width: 30px;
  top: 20px;

  cursor: pointer;
}

.menutableft {
  left: 15px;
}

.menutabright {
  right: 15px;
}
</style>
